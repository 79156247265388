<template>
	<div id="app" class="page-product">
		
		<img style="width: 100%;" :src="require('@/assets/images/temp4_top.png')" />

		<div class="bgbox-white  mydiv" style="">
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:20,offset:2}" :lg="{span:16,offset:4}">
				<div class="module1-conts" style="text-align: center;">
					<div class="module-title">
						<span class="title-cn">智能软件</span>
						<span class="title-en">INTELLIGENT SOFTWARE</span>
					</div>
					<div class="module-title">
						<span style="font-size: 20px;font-weight: bold;color: #1f1f1f;">客戶管理后台</span>
					</div>
				</div>
			</el-col>
			
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:24 }">
				<div class="flex_cc" style="padding: 0 20px;">
					<img style="max-width: 640;max-height: 288px;" :src="require('@/assets/images/temp4_01.png')" />
				</div>
			</el-col>
			
			<div class="flex_cc" style="width: 100%;">
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24}" :lg="{ span: 20, offset: 4 }">
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
						<div class="flex_cc wow slideInLeft" style="">
							<img :src="require('@/assets/images/temp4_02.png')" />
							<div class="title">预授权功能</div>
							<div class="text">方便押金管理</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
						<div class="flex_cc wow slideInLeft" style="">
							<img :src="require('@/assets/images/temp4_03.png')" />
							<div class="title">可接多种硬件</div>
							<div class="text">收银更高效</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
						<div class="flex_cc wow slideInLeft" style="">
							<img :src="require('@/assets/images/temp4_04.png')" />
							<div class="title">云南德合分期</div>
							<div class="text">支持客户贴息</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
						<div class="flex_cc wow slideInLeft" style="">
							<img :src="require('@/assets/images/temp4_05.png')" />
							<div class="title">支持客户贴息</div>
							<div class="text">负责到人</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
						<div class="flex_cc wow slideInLeft" style="">
							<img :src="require('@/assets/images/temp4_06.png')" />
							<div class="title">会员管理</div>
							<div class="text">增加锁客复购</div>
						</div>
					</el-col>
				</el-col>
			</div>
		</div>

		<div class="bgbox-white  mydiv" style="">
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:20,offset:2}" :lg="{span:16,offset:4}">
				<div class="module1-conts" style="text-align: center;">
					<div class="module-title">
						<span style="font-size: 20px;font-weight: bold;color: #1f1f1f;">SAAS收银软件</span>
					</div>
				</div>
			</el-col>
			
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:24 }">
				<div class="flex_cc" style="padding: 0 20px;">
					<img style="max-width: 640;max-height: 288px;" :src="require('@/assets/images/temp4_12.png')" />
				</div>
			</el-col>
			
			<div class="flex_cc" style="width: 100%;padding-top: 30px;">
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24}" :lg="{ span: 20, offset: 4 }">
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
						<div class="flex_cc wow slideInLeft" style="">
							<img :src="require('@/assets/images/temp4_07.png')" />
							<div class="title">智能收银</div>
							<div class="text">全面管理</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
						<div class="flex_cc wow slideInLeft" style="">
							<img :src="require('@/assets/images/temp4_08.png')" />
							<div class="title">无缝对接小程序</div>
							<div class="text">及外卖系统</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
						<div class="flex_cc wow slideInLeft" style="">
							<img :src="require('@/assets/images/temp4_09.png')" />
							<div class="title">丰富强大的</div>
							<div class="text">会员功能</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
						<div class="flex_cc wow slideInLeft" style="">
							<img :src="require('@/assets/images/temp4_10.png')" />
							<div class="title">数据罗盘</div>
							<div class="text">账目清晰</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
						<div class="flex_cc wow slideInLeft" style="">
							<img :src="require('@/assets/images/temp4_11.png')" />
							<div class="title">点对点结算</div>
							<div class="text">灵活到账</div>
						</div>
					</el-col>
				</el-col>
			</div>
		</div>

		<div class="bgbox-white  mydiv" style="">
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:20,offset:2}" :lg="{span:16,offset:4}">
				<div class="module1-conts" style="text-align: center;">
					<div class="module-title">
						<span style="font-size: 20px;font-weight: bold;color: #1f1f1f;">小程序</span>
						<span class="title-en" style="margin-top: 20px;font-size: 16px;">云南德合聚合微信支付、支付宝双通道小程序码，商家一码物享双平台小程序流量红利，营销功能丰富，</span>
						<span class="title-en" style="margin-top: 5px;font-size: 16px;">用户引流、留存、变现等一站式链路营销引爆营业额，餐饮、零售、休娱等各行业商家轻松实现智慧经营。</span>
					</div>
				</div>
			</el-col>
			
			<div class="module1-conts">
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span: 12 }">
						<div class="module1-item wow slideInLeft">
							<div class="mibox">
								<img :src="require('@/assets/images/temp4_13.png')" />
								<div class="proBox">
									<p class="proTitle">支付即会员</p>
									<p class="proTxt" style="margin-top: 15px;">通过设置会员等级及裙应福利，吸引顾客选择商</p>
									<p class="proTxt">城的会员，增强会员粘性，提升商城复购率。</p>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span: 12 }">
						<div class="module1-item wow slideInLeft">
							<div class="mibox">
								<img :src="require('@/assets/images/temp4_14.png')" />
								<div class="proBox">
									<p class="proTitle">小额动态储值</p>
									<p class="proTxt" style="margin-top: 15px;">储值功能帮助商家锁定会员，提升消费频次</p>
									<p class="proTxt">帮助商家沉淀资金，充实资金链。。</p>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span: 12 }">
						<div class="module1-item wow slideInLeft">
							<div class="mibox">
								<img :src="require('@/assets/images/temp4_16.png')" />
								<div class="proBox">
									<p class="proTitle">扫码点餐</p>
									<p class="proTxt" style="margin-top: 15px;">聚合微信、支付宝双通道流量，用户自主</p>
									<p class="proTxt">点餐，顾客体验好，商家效率高</p>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span: 12 }">
						<div class="module1-item wow slideInLeft">
							<div class="mibox">
								<img :src="require('@/assets/images/temp4_17.png')" />
								<div class="proBox">
									<p class="proTitle">自建外卖平台</p>
									<p class="proTxt" style="margin-top: 5px;">搭建自有外卖平台，帮助商家提升翻台率，增加坪</p>
									<p class="proTxt">效，摆脱对第三方平台外卖的依赖。</p>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span: 12 }">
						<div class="module1-item wow slideInLeft">
							<div class="mibox">
								<img :src="require('@/assets/images/temp4_18.png')" />
								<div class="proBox">
									<p class="proTitle">多人拼团</p>
									<p class="proTxt" style="margin-top: 5px;">利用拼团活动，让老客户主动分享，形成裂变传</p>
									<p class="proTxt">播、快速帮店铺拉新。</p>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span: 12 }">
						<div class="module1-item wow slideInLeft">
							<div class="mibox">
								<img :src="require('@/assets/images/temp4_15.png')" />
								<div class="proBox">
									<p class="proTitle">丰富营销</p>
									<p class="proTxt">新客专项优惠券，刺激用户首次消费；满减活</p>
									<p class="proTxt">动，层级，有效提升客单价和转化率；微信支</p>
									<p class="proTxt">支付优惠券，为商家引流，自动核销，方便高效</p>
								</div>
							</div>
						</div>
					</el-col>
				</el-col>
			</div>
		</div>
		
		
		
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
			}
		},

		created() {
			// this.quetyTab = this.$route.params.chooseTab
			// if(this.quetyTab){
			// 	this.chooseTab = this.quetyTab
			// 	this.itemData[this.chooseTab].ischose = true
			// 	if (this.chooseTab > 0) {
			// 		this.itemData[0].ischose = false
			// 	}
			// }


		},
		async mounted() {
			if (this.quetyTab!=undefined) {
				this.$nextTick(() => {
					document.querySelector(".mydiv").scrollIntoView(true)
				})
			}
		},
		methods: {
			menuClick(index) {
				this.chooseTab = index
				for (var i = 0; i < this.itemData.length; i++) {
					this.itemData[i].ischose = index == i ? true : false
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.itemview {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		.centerv {
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			img {
				width: 100%;
				margin: 0 auto;
				max-width: 312px;
				max-height: 312px;
				margin: 1px;
			}
		}

	}

	.table {
		height: auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.choosev {
		margin-left: 15px;
		font-size: 18px;
		color: #0C0C0C;
		border: 1px solid #E7E7E7;
		padding: 10px 40px;
	}

	.choosev2 {
		background: #EA150D;
		color: #ffffff;
	}

	#div:hover {
		background: #EA150D;
		color: #ffffff;
	}

	.bgbox-white {
		padding: 50px 10px;
		overflow: hidden;
		background: #fff;
		box-sizing: border-box;
	}

	.module-title {
		text-align: center;
		padding-bottom: 40px;

		.title-cn {
			display: block;
			font-size: 30px;
			font-weight: bold;
			color: #1f1f1f;
		}

		.title-en {
			display: block;
			font-size: 12px;
			font-weight: 400;
			color: #757575;
			line-height: 20px;
		}
	}
	.module-title.fc-white::after {
		content: "";
		width: 80px;
		height: 4px;
		background: #fff;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -40px;
		border-radius: 5px;
	}
	
	.flex_cc{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		.title{
			margin-top: 10px;
		}
	}
	.module1-conts {

		.module1-title {
			text-align: center;
			padding-bottom: 20px;

			span {
				display: block;
				font-size: 30px;
				color: #1f1f1f;
			}
		}

		.article {
			font-size: 16px;
			text-align: center;
		}

		.module1-msg {
			font-size: 18px;
			color: #757575;
			margin: 0 auto 10px auto;
			width: 100%;
			max-width: 840px;
			text-indent: 2em;

			span {
				display: inline-block;
				// text-align: center;
			}
		}

		.banner-img {
			object-position: left;
		}

		.module1-item {
			width: 100%;
			padding: 20px 10px;
			box-sizing: border-box;

			.mibox {
				// border: 1px solid #E4E4E4;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: relative;
			}

			img {
				display: block;
				width: 100%;
				max-width: 432px;
				max-height: 356px;
				margin: 0 auto;
				transition: all 0.3s ease;

				&:hover {
					transform: translate3d(0, -2px, 0);
					box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
				}
			}

			.proBox {
				display: block;
				// width: 100%;
				min-width: 262px;
				margin: 0 auto;
				padding: 10px 15px;
				text-align: center;
				position: absolute;
				bottom: 20px;

				.proTitle {
					font-size: 18px;
					color: #000000;
					font-weight: 600;
				}

				.proTxt {
					width: 100%;
					font-size: 15px;
					color: #000000;
				}
			}

		}
	}
</style>
